@import 'mat';
@import 'variables';
@import 'mixins';
@import 'bootstrap-override';
@import 'bootstrap-imports';
@import 'root';
@import 'helper-classes';
@import 'utilities';

.btn.disabled,
.btn:disabled,
button.disabled,
button:disabled  {
  cursor: default !important;
  &:hover {
    cursor: default !important;
  }
}
