// $font-bold: "Gilroy-Bold", "BPG Nino Mtavruli Bold", sans-serif;
// $font-bold: "DejaVu Sans Bold", sans-serif;
// $font-bold: "Gilroy-Bold", "BPG Phone Sans Bold", sans-serif;
// $font-bold: "Gilroy-Bold";
$font-bold: "Gilroy-Bold", "DejaVu Sans Bold", sans-serif;
$font-heavy: "Gilroy-Heavy";
$font-light: "Gilroy-Light";
$font-medium: "Gilroy-Medium";
$font-regular: "Gilroy-Regular", "Cambay-Regular";
$font-montserrat: "Montserrat", sans-serif;
$font-circular-std: "Circular Std", sans-serif;
$font-helvetica: "Helvetica", sans-serif;
$font-noto-regular: "Noto Serif Georgian";
$font-bpg-bold: "BPG Nino Mtavruli Bold";
