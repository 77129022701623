@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  @include media-breakpoint-up($breakpoint) {
    .border#{$infix} {
      border: $border-width solid $border-color !important;
    }

    .border#{$infix}-0 {
      border: 0 !important;
    }

    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }
    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }
    .border#{$infix}-left {
      border-left: $border-width solid $border-color !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
  }
}

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

.rounded-sm {
  border-radius: $border-radius-sm !important;
}

.rounded {
  border-radius: $border-radius !important;
}
.rounded-5 {
  border-radius: $border-radius-5;
}
.rounded-3 {
  border-radius: $border-radius-3 !important;
}

.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}
.rounded-top-0 {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.rounded-right-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rounded-left-0 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-lg {
  border-radius: $border-radius-lg !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: $rounded-pill !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.border-left-radius-0 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.border-right-radius-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

@each $size, $value in $spacers {
  .border-width-#{$size} {
    border-width: $value !important;
  }
}
