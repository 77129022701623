$color0: #000000;
$color1: #ffffff;
$color2: #181818;
$color3: #2354a5;
$color4: #f05a3c;
$color5: #ffc700;
$color6: #626262;
$color7: #f0f3f9;
$color8: #1d1d1d;
$color9: #888fa0;
$color10: #e6ebf4;
$color11: #200e32;
$color12: #060923;
$color13: #2d71f6;
$color14: #e5e5e5;
$color15: #d1d6de;
$color16: #95979e;
$color17: #ff0000;
$color18: #353c48;
$color19: #f4f4f4;
$color20: #424c5c;
$color21: #f3f5f8;
$color22: #e7e9eb;
$color23:#48AB55;
$color24:#E7E9EC;
$color25:#C4C4C4;
