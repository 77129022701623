@use 'colors'as c;
@use 'variables'as v;
@use 'breakpoint'as bp;
@import 'reset';
@import 'fonts';

.data-container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  &--relative {
    position: relative;
  }

  // @include bp.media('tablet') {
  //   width: 85.35871156661786%;
  //  //  width: 1166px;
  // }

  // @include bp.media('>=1166', '<=tablet-lg') {
  //   // width: 85.35871156661786%;
  //   width: 1166px;
  // }


  @include bp.media('<tablet') {
    width: 100%;
    //padding: 16px 16px 0 16px;
    //padding-left: 16px;
    //padding-right: 16px;
  }

  // @include bp.media('<phone') {
  //   width: 87.2%;
  // } removed for adding language switcher in header under sm
}


@include bp.media('>=1166px', '<=tablet-lg') {
  .data-container {
    width: 1166px;
  }
}

@include bp.media('<1166px', '>=tablet') {
  .data-container {
    width: 1024px;
  }
}





// @include bp.media(">=1166px", "<1400px") {
//   .data-container {
//     width: 1166px;
//   }
// }

.btn {
  outline: 0 none;
  border: 1px solid c.$color10;
  border-radius: 35px;
  background: transparent;
  padding: 0 32px;
  cursor: pointer;
  color: c.$color3;
  min-height: 45px;
  display: flex;
  align-items: center;
  font-family: v.$font-medium;
  text-decoration: none;

  >svg {
    stroke: c.$color3;

    path {
      stroke: c.$color3;
    }
  }

  &--main {
    border: 1px solid c.$color3;
    color: c.$color3;
    &:hover{
      color: c.$color3;
    }
  }

  &--white-filled {
    background-color: c.$color1;
    border: 1px solid c.$color1;
    color: c.$color2;
    &:hover{
      color: c.$color2;
    }

    >svg {
      stroke: c.$color2;

      path {
        stroke: c.$color2;
      }
    }
  }

  &__filled {
    margin: 0 auto;
    background-color: rgba(60, 116, 255, 1);
    border-radius: 12px;
    height: 48px;
    font-weight: 700;
    font-size: 16px;
    max-width: 310px;
    color: c.$color1;
    &:hover{
      color: c.$color1;
    }

    &:disabled {
      //background-color: c.$color16;
      cursor: initial;
      opacity: .5;
    }
  }

  &__bold {
    font-family: v.$font-bold;
  }

  &.size-m {
    min-height: 60px;
    font-size: 16px;
  }

  &.w-fluid {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  &:first-line {
    line-height: 0;
  }

  .caru-icon {
    margin-left: 12px;
  }

  //
}
