@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800&display=swap");

@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Bold"), url("../fonts/Gilroy-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Heavy"), url("../fonts/Gilroy-Heavy.woff") format("woff");
}


@font-face {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Light"), url("../fonts/Gilroy-Light.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Medium"), url("../fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Regular"), url("../fonts/Gilroy-Regular.woff") format("woff");
}

@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: normal;
  src: local("Circular Std"), url("../fonts/Circular Std Medium 500.ttf") format("ttf");
}

@font-face {
  font-family: "Noto Serif Georgian";
  font-style: normal;
  font-weight: normal;
  src: local("Noto Serif Georgian"), url("../fonts/noto/NotoSerifGeorgian-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Cambay-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Cambay-Regular"), url("../fonts/cambay/Cambay-Regular.woff") format("woff");
}

// BPG Nino
@font-face {
  font-family: "BPG Nino Mtavruli Bold";
  src: url("../fonts/bpg/bpg-nino-mtavruli-bold-webfont.eot"); /* IE9 Compat Modes */
  src: url("../fonts/bpg/bpg-nino-mtavruli-bold-webfont.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/bpg/bpg-nino-mtavruli-bold-webfont.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/bpg/bpg-nino-mtavruli-bold-webfont.woff") format("woff"),
    /* Pretty Modern Browsers */ url("../fonts/bpg/bpg-nino-mtavruli-bold-webfont.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/bpg/bpg-nino-mtavruli-bold-webfont.svg#bpg_nino_mtavrulibold")
      format("svg"); /* Legacy iOS */
}

// DEJAVU Sans
@font-face {
  font-family: "DejaVu Sans Bold";
  src: url("../fonts/dejavusans/dejavu-sans-bold-webfont.eot"); /* IE9 Compat Modes */
  src: url("../fonts/dejavusans/dejavu-sans-bold-webfont.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/dejavusans/dejavu-sans-bold-webfont.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/dejavusans/dejavu-sans-bold-webfont.woff") format("woff"),
    /* Pretty Modern Browsers */ url("../fonts/dejavusans/dejavu-sans-bold-webfont.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/dejavusans/dejavu-sans-bold-webfont.svg#dejavu_sansbold") format("svg"); /* Legacy iOS */
}



@font-face {
  font-family: 'SF-Georgian';
  src: url('../fonts/SF-Georgian.ttf') format('truetype');
}


@font-face {
  font-family: 'Euro5';
  src: url('../fonts/eurov5.ttf') format('truetype');
}
