@use 'colors' as c;

// for dropdown
.option-img {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  vertical-align: middle;
  margin-right: 16px;

  img {
    width: 100%;
  }
}

.custom-material {
  ::ng-deep {
    input:-webkit-autofill {
      -webkit-background-clip: text;
    }
    .mat-form-field .mat-form-field-flex {
      background-color: white;
      border: 1px solid #e7e9eb;
      font-size: 14px;

      .mat-form-field-label {
        color: c.$color16;
      }
    }
    ng-dirty.mat-form-field-invalid .mat-form-field-flex,
    .ng-touched.mat-form-field-invalid .mat-form-field-flex {
      border: 1px solid c.$color17;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
      border-radius: 10px !important;
    }

    .mat-form-field-underline {
      display: none;
    }
    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
      background-color: c.$color3;
    }
    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
      background-color: c.$color13;
    }
    .mat-checkbox-background,
    .mat-checkbox-frame {
      border-radius: 5px;
    }
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: transparent;
    }
  }
}

.no-padding mat-dialog-container {
  padding: 0;
}
.mat-tooltip {
  font-size: 20px;
}
.mat-dialog-terms-and-conditions {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  display: flex;
  justify-content: flex-end;
  .mat-dialog-container {
    max-width: 500px;
    width: 100%;
    border-radius: 0px !important;
  }
}

.cdk-overlay-container {
  .mat-dialog-container {
    border-radius: 10px;
  }
}

.mat-checkbox {
  .mat-checkbox-layout {
    .mat-checkbox-inner-container {
      width: 20px;
      height: 20px;
      .mat-checkbox-frame {
        border-radius: 6px !important;
        border-color: #E7E7E7;
      }
    }
  }
  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: transparent;
    // background-image: url('../../../apps/root-app/src/assets/images/svg/checked-black.svg');
    background-image: url('../../../assets/images/svg/checked-black.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    svg.mat-checkbox-checkmark {
      display: none;
    }
  }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,.mat-checkbox .mat-ripple-element {
    background-color: unset;
}
