@use 'colors' as c;
@use 'variables' as v;

.action-box {
  width: 88px;
  height: 88px;
  background-color: c.$color1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    border-radius: 8px;
    background-color: rgba(c.$color3, 0.07);
    width: 56px;
    height: 56px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      vertical-align: middle;
    }
  }
}
