@use '../../../../../libs/theme/src/lib/scss/variables' as v;

.outline-none {
  outline: none !important;
}
.cursor-pointer {
  cursor: pointer;
}
.translate-y-1px {
  transform: translateY(1px);
}
.header-shadow {
  box-shadow: 0 0 4px 0 rgba(52, 52, 52, 0.26);
}
.dashboard-card-shadow {
  box-shadow: 0 3px 3px -3px rgba(52, 52, 52, 0.25);
}
.dashboard-table-shadow {
  box-shadow: 0 3px 4px -3px rgba(52, 52, 52, 0.25);
}

.h-u-lg-42px {
  @media screen and (max-width: 1366px) {
    height: 42px !important;
  }
}
.overflow-overlay {
  overflow: auto;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.global-custom-scroll {
  @include global-custom-scroll;
}
.max-h-u-sm-unset {
  @include media-breakpoint-down(custsm) {
    max-height: unset !important;
  }
}
.hover-wrapper {
  .hover-element {
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    @include transition;
  }
  &:hover {
    .hover-element {
      visibility: visible;
      opacity: 1;
    }
  }
}
.mat-dialog-boxshadow {
  box-shadow: 0 5px 30px -10px rgba(13, 18, 46, 0.5);
}
.h-lg-48px {
  @include media-breakpoint-up(lg) {
    height: 48px;
  }
}
.mw-0 {
  max-width: 0px;
}
.mh-565px {
  max-height: 565px;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-position-center {
  background-position: center center;
}
.flex-basis-48px {
  flex-basis: 48px;
}
.text-decoration-underline {
  text-decoration: underline;
}
.overflow-y-auto {
  overflow-y: auto;
}
.font-color-inherit {
  color: inherit;
}
.left-0px {
  left: 0px;
}
.pointer-events-none {
  pointer-events: none;
}
.m-h-300px {
  max-height: 300px;
}
.resize-none {
  resize: none;
}
.bg-size-contain {
  background-size: contain;
}
.flex-basis-656 {
  flex-basis: 656px;
}
.bottom-n20px {
  bottom: -20px;
}
.filter-invert-100-contrast-200 {
  filter: invert(100%) contrast(200%);
}
.translate-y-n32-5 {
  transform: translateY(-32.5px);
}
.translate-y-45px {
  transform: translateY(45px);
}
.rotate-n180 {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.overflow-unset {
  overflow: unset;
}
.overflow-visible {
  overflow: visible;
}
.left-12px {
  left: 12px;
}
.left-18px {
  left: 18px;
}
.translate-y-n1px {
  transform: translateY(-1px);
}
.table-layout-fixed {
  table-layout: fixed;
}
.h-unset {
  height: unset !important;
}
.rotate-90deg {
  transform: rotate(90deg);
}
.letter-spacing-1px {
  letter-spacing: 1px;
}
.font-weight-600 {
  font-weight: 600;
}
.idle-popup-boxshadow {
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1);
}
.z-index-10 {
  z-index: 10;
}
.translate-y-4px {
  transform: translateY(4px);
}
.bottom-28px {
  bottom: 28px;
}
.opacity-07 {
  opacity: 0.7;
}
.p-24px {
  padding: 24px;
}
.pr-24px {
  padding-right: 24px;
}
.m-h-620px {
  max-height: 620px;
}
.max-h-42px {
  max-height: 42px;
}
.m-h-65vh {
  max-height: 65vh;
}
.max-h-36vh {
  max-height: 36vh;
}
.min-h-565px {
  min-height: 565px;
}
.min-w-95px {
  min-width: 95px;
}
.min-h-128px {
  min-height: 128px;
}
.min-w-10px {
  min-width: 10px;
}
.min-w-36px {
  min-width: 36px;
}
.min-w-150px {
  min-width: 150px;
}
.min-w-223px {
  min-width: 223px;
}
.left-unset {
  left: unset;
}
.visibility-hidden {
  visibility: hidden;
}
.pointer-events-none {
  pointer-events: none;
}

.pointer-events-none-disabled {
  pointer-events: none;
  background-color: #f6f6f6 !important;
  color: rgba(52, 52, 52, 0.5);
}
.payment-app-buttons-box-shadow {
  box-shadow: 0px 0px 6px #3a3e5633;
}
.truncate-after-3-lines-w-90pct {
  display: -webkit-box;
  max-width: 90%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.min-w-36px {
  min-width: 36px;
}
.w-sm-468px {
  @include media-breakpoint-up(sm) {
    width: 468px;
  }
}
.font-family-bold{
  font-family: v.$font-bold;
}
.font-family-regular{
  font-family: v.$font-regular;
}
// right-position-generator

$right-positions: (
  '13px': 13px,
  '25px': 25px,
  '8px': 8px,
  '10px': 10px,
  '11px': 11px,
  '0': 0,
  '16px': 16px,
  '24px': 24px,
  'unset': unset,
);

@each $size, $value in $right-positions {
  .right-#{$size} {
    right: $value !important;
  }
}

// top-position-generator

$top-positions: (
  '109pct': 109%,
  '0px': 0px,
  '8px': 8px,
  '20px': 20px,
  '17px': 17px,
  '30px': 30px,
  '32px': 32px,
  '50pct': 50%,
  '85pct': 85%,
  '120pct': 120%,
);

@each $size, $value in $top-positions {
  .top-#{$size} {
    top: $value !important;
  }
}

// line-heights-generator

$line-heights: (
  'normal': normal,
  '0-8': 0.8,
  '1-3': 1.3,
  '1-4': 1.4,
  '1-5': 1.5,
  '9px': 9px,
  '18px': 18px,
  '24px': 24px,
  '28px': 28px,
  '33px': 33px,
  '42px': 42px,
);

@each $size, $value in $line-heights {
  .line-h-#{$size} {
    line-height: $value !important;
  }
}

// heights-generator

$heights: (
  '5px': 5px,
  '8px': 8px,
  '10px': 10px,
  '15px': 15px,
  '21px': 21px,
  '24px': 24px,
  '32px': 32px,
  '34px': 34px,
  '36px': 36px,
  '42px': 42px,
  '48px': 48px,
  '54px': 54px,
  '60px': 60px,
  '64px': 64px,
  '67px': 67px,
  '72px': 72px,
  '80px': 80px,
  '83px': 83px,
  '84px': 84px,
  '87px': 87px,
  '88px': 88px,
  '90px': 90px,
  '98px': 98px,
  '100vh': 100vh,
  '130px': 130px,
  '132px': 132px,
  '152px': 152px,
  '192px': 192px,
  '200px': 200px,
  '245px': 245px,
  '390px': 390px,
  '427px': 427px,
  '448px': 448px,
  '450px': 450px,
  '454px': 454px,
  '455px': 455px,
  '480px': 480px,
  '500px': 500px,
  '515px': 515px,
  '524px': 524px,
);

@each $size, $value in $heights {
  .h-#{$size} {
    height: $value !important;
  }
}

// widths generator

$widths: (
  '1px': 1px,
  '5px': 5px,
  '8px': 8px,
  '10px': 10px,
  '24px': 24px,
  '36px': 36px,
  '42px': 42px,
  '48px': 48px,
  '54px': 54px,
  '60px': 60px,
  '64px': 64px,
  '65px': 65px,
  '68px': 68px,
  '75px': 75px,
  '85px': 85px,
  '88px': 88px,
  '89px': 89px,
  '99px': 99px,
  '100px': 100px,
  '100vw': 100vw,
  '102px': 102px,
  '105px': 105px,
  '113px': 113px,
  '116px': 116px,
  '120px': 120px,
  '122px': 122px,
  '132px': 132px,
  '133px': 133px,
  '136px': 136px,
  '138px': 138px,
  '142px': 142px,
  '155px': 155px,
  '161px': 161px,
  '168px': 168px,
  '192px': 192px,
  '195px': 195px,
  '200px': 200px,
  '258px': 258px,
  '272px': 272px,
  '320px': 320px,
  '377px': 377px,
  '382px': 382px,
  '400px': 400px,
  '430px': 430px,
  '468px': 468px,
  '548px': 548px,
);

@each $size, $value in $widths {
  .w-#{$size} {
    width: $value !important;
  }
}

// max-widths-generator

$max-widths: (
  '65pct': 65%,
  '68pct': 68%,
  '78pct': 78%,
  '80pct': 80%,
  '90px': 90px,
  '98px': 98px,
  '125px': 125px,
  '150px': 150px,
  '176px': 176px,
  '210px': 210px,
  '250px': 250px,
  '270px': 270px,
  '304px': 304px,
  '312px': 312px,
  '315px': 315px,
  '316px': 316px,
  '320px': 320px,
  '328px': 328px,
  '390px': 390px,
  '458px': 458px,
  '468px': 468px,
  '490px': 490px,
  '500px': 500px,
  '600px': 600px,
  '628px': 628px,
  '656px': 656px,
  '730px': 730px,
  '750px': 750px,
  '960px': 960px,
);

@each $size, $value in $max-widths {
  .mw-#{$size} {
    max-width: $value !important;
  }
}
// needs to go to main-pages.scss after it is temporarly here
.inventory-ingredient-chooser-popup {
  .box-shadow {
    box-shadow: 0px 0px 4px #3434342e;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .popup-item {
    border: 2px solid transparent;
    transition: all 0.15s linear;
    border-radius: 5px;
    p {
      transition: all 0.15s linear;
    }
    &:hover {
      border: 2px solid #4563ff;
      app-icon {
        span {
          svg {
            .fill-change {
              fill: #4563ff;
            }
            .stroke-change {
              stroke: #4563ff;
            }
          }
        }
      }
      p {
        color: #4563ff;
      }
    }
  }
}
