@mixin make-font-caps() {
  -webkit-font-feature-settings: 'case';
  font-feature-settings: 'case' on;
}

@mixin global-custom-scroll {
  border-right: 2px solid transparent;
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 24px;
    background-color: $scroll-gray;
  }
}
@mixin body-custom-scroll {
  &::-webkit-scrollbar {
    width: $body-scroll-width;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scroll-gray;
  }
}
@mixin hide-scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
