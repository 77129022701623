@use 'variables' as v;
@use 'colors' as c;
@use 'breakpoint' as bp;

/* You can add global styles to this file, and also import other style files */
@import './assets/styles/index.scss';
@import 'reusable';
@import 'theme';
@import 'swiper/scss';
@import 'app/shared/styles/material-inputs';

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";



::ng-deep .bottom-dialog {
  .p-dialog-content{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: red !important;
  }
}

div.p-dialog-mask {
  //"none" does not prevent interaction with the background and also doesn't capture the click for closing the modal dialog
  pointer-events: auto;
}
.city-dialog-responsive{
  overflow: auto;
  position: fixed !important;
  right: 120px !important;
  //top: 380px !important;
  @media only screen and (max-width: 1240px) {
    position: relative !important;
    right: 0 !important;
    //top: 100px !important;]
  }

  @media only screen and (max-width: 500px) {
    top: 330px !important;
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100vw !important;
    max-width: 100% !important;
    margin: 0 !important;
    //height: 464px !important;
   // top: 300px !important;
    .mat-dialog-container{
      border-radius: 20px 20px 0 0 !important;
    }
  }
}

.color-green{
  background-color: #48AB55 !important;
  color: white;
  border-radius: 30px !important;
}

.color-red{
  background-color: #ce152d !important;
  color: white;
  border-radius: 30px !important;
}

.responsive-dialog{
  width: 80vh;
}

.dialog-block .mat-dialog-container{
  border-radius: 20px !important;
}

.mat-tooltip {
  font-size: 12px; /* Adjust the font size as needed */
}

.mat-simple-snackbar{
  justify-content: center!important;
}

:root {
  --header-height: 78px;
}

html {

  scroll-behavior: smooth;
}

.urgent-container .mat-dialog-container{
  border-radius: 20px !important;
  width: 411px;
  height: 342px;
}

.custom-dialog-container .mat-dialog-container{
  padding: 0 !important;
  border-radius: 16px !important;
  border-top-right-radius: 16px !important;
}


.custom-container .mat-dialog-container{
  border-radius: 0px !important;
  padding: 0;
}

.custom-container .mat-radio-label{
  padding: 20px ;
}

.custom-container .mat-radio-label-content{
  width: 100% !important;
}

html,
body {
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: "Gilroy-Regular", "DejaVu Sans Regular", sans-serif;
  background-color: c.$color21;

  .btn {
    &:focus {
      box-shadow: none;
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  caruspace-credit-cards {
    width: 100%;
    height: 100%;
    display: block;
  }
}

caruspace-dash {
  .content-view {

    display: flex;
    flex-direction: column;

    caruspace-order-flow {
      flex-grow: 1;

      .of-main {
        flex-direction: column;

        .of-container {
          flex-grow: 1;

          caruspace-picked-up {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            .pu-container {
              flex-grow: 1;
              border-top: 1px solid #F2F3F6;

            }
          }
        }
      }
    }
  }
}

.cdk-global-scrollblock {
  overflow-y: hidden !important;
}

//.input-with-mask {
//  .mat-form-field {
//    .mat-form-field-infix {
//      .mat-form-field-label-wrapper {
//        .mat-form-field-label {
//          transform: translateY(-0.59375em) scale(0.75) !important;
//        }
//      }
//    }
//  }
//}

.mat-auto-slider {
  width: 772px;
  height: 530px;
  @include bp.media('<768px') {
    width: 90vw !important;
    max-width: 90% !important;
    height: 60%;
    overflow: hidden;
    @include bp.media('<741px') {
      width: 90vw !important;
      max-width: 90% !important;
      height: 69%;
      overflow: hidden;
    }
    @include bp.media('>768px') {
      .mat-auto-slider {
        height: 750px;
      }
    }

    .mat-dialog-container {
      background-color: transparent;
      display: flex;
      align-items: flex-end;

      caruspace-images-preview {
        // height: 470px;
        display: flex;
        width: 100%;
      }
    }
  }
}

html body:has(caruspace-pdf) {
  caruspace-header {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  .content {
    padding-top: 0px !important;
  }
}

@media print {
  @page {
    size: auto; /* auto is the initial value */
    margin: 0; /* this affects the margin in the printer settings */
  }
  body {
    background-color: white;
    padding-top: 5px;
    padding-bottom: 22px;
    zoom: 80%;
  }
}

.profile-dash:has(.picked-up-global-wrapper,.working-global-wrapper, .finished-step-global-wrapper){
  // background-color: red !important;
  .content-view{
    width: 80% !important;
    margin-left:0px !important;
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px !important;
    @include bp.media("<=desktop") {
      width: 100% !important;
      .footer  {
        .assistant{
          .info{
            position: fixed;
            background: transparent;
            min-width: unset !important;
            width: 60px;
            right: 0px;
            z-index: 10;
            top: unset;
            bottom: 271px;
            right: 16px;
            .image{
              width: 48px !important;
              min-width: 48px !important;
              height: 48px !important;
              background-color: #E9EEF6;
              padding: 6px;
              img{
                border-radius: 50%;
              }
            }
            .details{
              display: none;
            }
            .phone{
              position: relative;
              z-index: 10;
              display: flex;
              justify-content: flex-end;
              button{
                margin-top: -20px;
                background-color: c.$color3 !important;
                width: 28px;
                height: 28px;
                min-width: 28px;
                min-height:28px;
                padding: 0px !important;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }

    .current-step{
      display: none;
    }

    .status-bar{
      background-color: white;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      padding: 24px !important;
    }
    // for working status component start
    .working-global-wrapper{
      .main-section{
        width: 100%;
        background-color: white;
        min-height: 250px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        display: flex;
         justify-content: center;
        flex-direction: column;
        padding-top: 20px;
      }
      .order-comments{
        width: 100%;
        background-color: white;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    // for working status component end
    // for pickup status component start
    .picked-up-global-wrapper{
      margin: 0 auto;
      width: calc(100% - 32px);
      background-color: white;
      min-height: 250px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      padding:0px 24px 24px 24px;
      .payment-actions-height{
        height: 430px !important;
        @include bp.media("<=desktop") {
          height: 350px !important;

        }
      }
      .payment-actions{
        box-shadow: 0px -2px 5px 0px #00000008;
        border-top: 1px solid #E7E7E7;
        position: absolute;
        background-color: white;
        border-radius: 12px;
        top:0px;
        right: -27%;
        width: 280px;
        padding: 24px;
        height: 290px;
        @include transition;
        @include bp.media("<=desktop") {
          right: unset;
          left: 0px;
          z-index: 20;
          position: fixed;
          width: 100%;
          bottom: 0px;
          margin-bottom: 0px;
          height: 90px;
          padding-top: 20px;
          padding-bottom: 15px;
          padding-left: 16px;
          padding-right: 16px;
          box-shadow: 0px -4px 32px 0px rgba(0, 0, 0, 0.08);
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          justify-content: flex-start;
          .btn{
            margin-bottom: -7px !important;
          }
        }
      }
    }

    // for pickup status component end
    // finished step status component start
    .finished-step-global-wrapper{
      .confirm{
        padding-top: 8px;
        width: 100%;
        background-color: white;
        min-height: 250px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        margin-bottom: 24px;
        padding-bottom: 64px;
      }
      .payment-actions{
        position: absolute;
        background-color: white;
        border-radius: 12px;
        top:0px;
        right: -27%;
        width: 280px;
        padding: 24px;
        height: 200px;
      }
      .order-comments{
        width: 100%;
        background-color: white;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    // finished step status component end
    .footer{
      width: 100%;
      background-color: white;
      padding: 24px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      margin-top: 24px;
      border: 0px;
    }

  }
  .footer .info{
    position: absolute;
    right: -27%;
    background-color: white;
    border-radius: 12px;
    border: 0px !important;
    width: 280px;
    padding: 24px 16px;
    padding-right: 0px !important;
    .image{
      width: 36px !important;
      min-width: 36px;
      height: 36px !important;
    }

  }

  @include bp.media("<=desktop") {
    .picked-up-global-wrapper , .finished-step-global-wrapper{
      .payment-actions{
        top: unset !important;
        bottom: 0px;
      }
    }
  }
}

.profile-dash:has(.working-global-wrapper , .finished-step-global-wrapper){
  .footer .info{
    top: 0px;
  }
}

.profile-dash:has(.picked-up-global-wrapper .payment-actions){
  .content-view{
    @include bp.media("<=desktop") {
      padding-bottom: 155px;
    }
  }
  .top-stars{
    top: 448px !important;
  }
  .footer .info{
    top: 310px;
  }

}

.profile-dash:has(.picked-up-global-wrapper){
  .footer .info{
    top: 0px;
  }
}

.cdk-overlay-container:has(.transfer-to-account-wrapper){
  .cdk-global-overlay-wrapper{
    @include bp.media("<=desktop") {
    display: flex;
    align-items: flex-end !important;
    .cdk-overlay-pane{
        max-width: 100% !important;
        width: 100%;
       }
    }
  }
}

.profile-dash:has(.finished-step-global-wrapper){
  @include bp.media("<=desktop") {
    .footer .assistant .info{
      bottom: 30px !important;
    }
   }
 }

.cdk-overlay-container:has(caruspace-confirmation-modal){
  @include bp.media('<768px') {
    .cdk-overlay-pane{
      min-width: 95vw;
    }
   }
}

body:has(.banner) {
  .navbar{
    top: 50px;
  }
  .content{
    padding-top: calc(var(--header-height) + 50px);
  }
  .banner{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 20;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    min-height: 50px;
    background-color: c.$color3;
    .btn{
      color: white;
      font-size:12px;
      height: 25px;
      min-height: 25px;
      border: 1px solid white;
      line-height: normal;
      white-space: nowrap;
      &:hover{
        background-color: white;
        color: c.$color3;
      }
    }
  }
}

.cdk-global-scrollblock:has(body .cdk-overlay-container .cdk-global-overlay-wrapper .mat-dialog-container .pay-with-card-loading-wrapper){
   position: relative;
   overflow-y: auto !important;
}

.cdk-overlay-container:has(.cdk-global-overlay-wrapper .mat-dialog-container .pay-with-card-loading-wrapper.minimized){
   pointer-events: none;
  .cdk-overlay-backdrop{
   pointer-events: none;
    background-color: transparent;
   }
   .cdk-global-overlay-wrapper {
  @include transition;
    pointer-events: none;
    align-items: flex-end !important;
    justify-content: flex-end !important;
    .mat-dialog-container{
      padding: 0px;
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 50%;
      .pay-with-card-loading-wrapper{
        position: relative;
        background-color: #2354A5;
        .minimize-btn{
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          left: 0px;
          top: 0px;
          z-index: 10;
          img{
            display: none;
          }
        }
        .loader-mini{
          display: flex;
        }
      }
    }
   }
   .cdk-overlay-pane{
    min-width: unset;
   }
}


.btnShared {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent !important;
  cursor: pointer;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1.5px solid #0000003D;
  box-shadow: 0px 2px 1px 0px #FFFFFF29 inset;
  border-radius: 12px;
  background: #3F75FA;
  width: 100%;
  height: 50px;
  padding: 12px;
  font-family: SF-Georgian, Inter, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-tap-highlight-color: transparent !important;
}


button {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-tap-highlight-color: transparent !important;
}
